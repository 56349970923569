












































































































































































































































import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ErrorComponent from '../components/ErrorComponent.vue';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import ValidationSummaryComponent from '../components/ValidationSummaryComponent.vue';

import { handleError } from '../lib/shared';
import { getUserAccountSettings, updateUserAccountSettings, getVendor, updateVendor } from "../store/rest.service";
import { UserAccountSettings, CurrentUser, Vendor, ImpactTargetsMeta, CommonMeta } from "../store/models";
import { EmptyUserAccountSettings, EmptyVendor } from "../store/models-empty";
import { getTimezones } from '../lib/datetime';
import * as _ from 'lodash';

@Component({
  components: {
    ErrorComponent,
    SpinnerComponent,
    ValidationSummaryComponent,
  },
})
export default class AccountSettings extends BaseComponent {

  protected baseRoute: string = '/insights';

  private arrErrors: Error[] = [];
  private updateValidation: number = 0;

  private formDirty: boolean = false;
  private editedId: string = '';
  private accountSettingsLoading: boolean = false;
  private accountSettingsSaving: boolean = false;
  private editedItem: Vendor = _.cloneDeep(EmptyVendor);
  private timeZones: any[] = [];
  private selectedOrgName: string = '';

  protected mounted() {
    this.timeZones = getTimezones();
    this.editedId = this.$store.getters['local/CURRENT_ORG'] as string;
    this.getVendor();
    this.selectedOrgName = this.getCurrentOrgName(this.editedId);
  }

  private save() {
    if (!this.accountSettingsSaving) {
      if ((this.$refs.form1 as any).validate()) {
        this.updateValidation++;
        this.updateUserAccountSettings();
      } else {
        this.updateValidation++;
      }
    }
  }

  private getVendor() {
    this.accountSettingsLoading = true;
    getVendor(this.editedId).then((response) => {
      this.editedItem = response;
      this.setDefaultTargets();
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.accountSettingsLoading = false;
    });
  }

  private close() {
    this.changeRoute(this.baseRoute);
  }

  private updateUserAccountSettings() {
    this.accountSettingsSaving = true;
    const vendorETag = (this.editedItem.common as CommonMeta).etag as string;
    const vendorToSave = this.removeCommonProperties(_.cloneDeep(this.editedItem));
    if (!this.editedItem.vendor.hasProvisionalCreator) {
      this.editedItem.vendor.hasProvisionalCreator = '';
    }
    if (this.editedItem.vendor.defaultExpenseClass) {
      delete this.editedItem.vendor.defaultExpenseClass;
    }
    updateVendor(this.editedId, vendorToSave, vendorETag).then((response) => {
      this.formDirty = false;
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.getVendor();
      this.accountSettingsSaving = false;
    });
  }

  private setDefaultTargets() {
    const impactTargets: ImpactTargetsMeta = this.editedItem.vendor.impactTargets as ImpactTargetsMeta;
    if (!impactTargets.indigenousProjectBenefit) {
      impactTargets.indigenousProjectBenefit = 0;
    }
    if (!impactTargets.indigenousCommunityContribution) {
      impactTargets.indigenousCommunityContribution = 0;
    }
    if (!impactTargets.indigenousEmployment) {
      impactTargets.indigenousEmployment = 0;
    }
    if (!impactTargets.diverseProjectBenefit) {
      impactTargets.diverseProjectBenefit = 0;
    }
    if (!impactTargets.diverseEmployment) {
      impactTargets.diverseEmployment = 0;
    }
    if (!impactTargets.socialProcurement) {
      impactTargets.socialProcurement = 0;
    }
    if (!impactTargets.diverseCommunityContribution) {
      impactTargets.diverseCommunityContribution = 0;
    }
  }

}
